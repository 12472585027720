import React from 'react'
import { Button, Result } from 'antd'
import { Link } from 'react-router-dom'

export const OrderServiceFailure = () => {
  return (
    <Result
      status="500"
      title="Order Service Unavailable"
      subTitle="We're currently unable to process orders. Please try again later. We’ll notify you as soon as the service is back up."
      extra={
        <Link to="/">
          <Button type="primary">Back to Home</Button>
        </Link>
      }
    />
  )
}
