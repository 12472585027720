import {
  Button,
  Card,
  Form,
  Input,
  Radio,
  Select,
  Row,
  Tooltip,
  Switch,
  Alert,
  Divider,
  InputNumber,
} from 'antd'
import {
  buffers,
  getAvailableContainersType,
  controls,
  carrierStatus,
  tissueBlockSize,
  plateLayout,
  isBalsamic,
  balsamicQC,
  balsamicReferenceGenomes,
  isMIPDNA,
  isTomte,
} from '../../services/helpers/constants'
import { Parents, Sex } from '../../services/interfaces'
import { DeleteTwoTone } from '@ant-design/icons'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import styles from './Forms.module.css'
import {
  getConcentrationRules,
  getIsPlate,
  isBufferRequired,
  isPANPAZApplication,
  isSampleOld,
  validateBaitSet,
  validateWellIsFree,
} from '../../pages/OrderForm/helpers'
import { DeletePopConfirm } from '../DeletePopConfirm'
import { isNil } from 'ramda'
import dayjs from 'dayjs'
import { UserContext } from '../../services/contexts/userContext'
import { getApplication } from 'services/StatusDbApi'
import { ApplicationField } from './Fields/ApplicationField'
import { SubjectIdField } from './Fields/SubjectIdField'

export const CaseSampleForm = ({
  index,
  remove,
  options,
  form,
  caseName,
  parentsSamples,
  setParentSamples,
  analysisType,
  skipReceptionControl,
}) => {
  const [isFormalineSource, setIsFormalineSource] = useState(false)
  const [isOtherSource, setIsOtherSource] = useState(false)
  const [hasContainer, setHasContainer] = useState(false)
  const [isPlate, setIsPlate] = useState(false)
  const [isTumour, setIsTumour] = useState(false)
  const [containerName, setContainerName] = useState<string>()
  const [application, setApplication] = useState<string>()
  const [source, setSource] = useState<string>()
  const [sampleId, setSampleId] = useState<string>()
  const [isExisting, setIsExisting] = useState<boolean>(false)
  const [receivedAt, setReceivedAt] = useState<string>()
  const userContext = useContext(UserContext)
  const [applicationObj, setApplicationObj] = useState<any>()
  const [containerNameRequirement, setContainerNameRequirement] = useState<boolean>(false)
  const [subjectId, setSubjectId] = useState<string>()
  const deleteSample = () => {
    remove(index)
    prefillConditionalFields()
  }

  const buildParentsObject = (sampleId, key = index) => {
    const localSamples = form.getFieldValue('cases')[caseName]?.samples
    const localParents: Parents = { father: [], mother: [] }
    localSamples.forEach((sample) => {
      if (sample?.name !== sampleId) {
        if (sample?.sex === 'male') localParents.father.push({ name: sample?.name, id: key })
        else if (sample?.sex === 'female') localParents.mother.push({ name: sample?.name, id: key })
      }
    })
    setParentSamples(localParents)
  }

  const prefillConditionalFields = useCallback(() => {
    const {
      application,
      source,
      container,
      name,
      container_name,
      internal_id,
      tumour,
      received_at,
      subject_id,
    } = form.getFieldValue('cases')[caseName]?.samples[index] || ''
    setIsExisting(!isNil(internal_id))
    if (!isNil(internal_id)) setReceivedAt(dayjs(received_at).format('YYYY-MM-DD'))
    setSampleId(name)
    setIsTumour(tumour)
    setSource(source)
    setApplication(application)
    setSubjectId(subject_id)
    if (application && skipReceptionControl) {
      getApplication(userContext, application).then((res) => {
        setApplicationObj(res)
      })
    }
    setIsPlate(getIsPlate(container))
    setContainerName(container_name)
    buildParentsObject('')
    setHasContainer(!container?.includes('No container'))
    setContainerNameRequirement(container === '96 well plate')
    if (source) {
      setIsFormalineSource(source?.includes('FFPE'))
      setIsOtherSource(source?.includes('other'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, caseName, index, userContext])

  useEffect(() => {
    prefillConditionalFields()
  }, [prefillConditionalFields])

  return (
    <Card>
      {isExisting && (
        <>
          <Alert
            message="Existing sample. Erroneous values might delay analysis."
            type="info"
            showIcon
            style={{ marginBottom: 12 }}
          />
          {isSampleOld(receivedAt) && (
            <Alert
              message="You have placed an order of old data. Due to the legacy structure of this old data we will have to manually process it. We cannot guarantee fast delivery of such data - If time is of concern please consider asking us about possible re-sequencing of existing material or sending in a new aliquot."
              type="warning"
              showIcon
              style={{ marginBottom: 12 }}
            />
          )}
        </>
      )}
      <Row className={styles.row}>
        <Form.Item
          label="Name"
          name={[index, 'name']}
          rules={[
            {
              required: true,
              message: 'required',
            },
            {
              message: 'Can include only numbers, letters and dash',
              pattern: new RegExp(/^[a-zA-Z0-9-]*$/),
            },
          ]}
          required
        >
          <Input
            style={{ width: 200 }}
            disabled={isExisting}
            onChange={(e) => {
              setSampleId(e.target.value)
              if (isMIPDNA(analysisType) || isTomte(analysisType))
                buildParentsObject(e.target.value)
            }}
          />
        </Form.Item>
        {(isMIPDNA(analysisType) || isTomte(analysisType)) && (
          <Form.Item
            required={!isExisting}
            rules={[{ required: !isExisting }]}
            name={[index, 'status']}
          >
            <Radio.Group size="small">
              {carrierStatus.map(({ status }) => (
                <Radio.Button key={status} value={status}>
                  {status}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
        )}
        {isBalsamic(analysisType) && (
          <Form.Item label="Is Tumour" name={[index, 'tumour']} valuePropName="checked">
            <Switch onChange={(e) => setIsTumour(e)} disabled={isExisting} />
          </Form.Item>
        )}
        <Form.Item
          label="Sex"
          name={[index, 'sex']}
          rules={[{ required: !isExisting }]}
          required={!isExisting}
        >
          <Select
            disabled={isExisting}
            style={{ width: 100 }}
            onChange={() => {
              buildParentsObject(sampleId)
            }}
          >
            {Object.keys(Sex).map((sex) => (
              <Select.Option key={sex} value={sex}>
                {sex}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Control" name={[index, 'control']}>
          <Select style={{ width: 90 }} disabled={isExisting}>
            {controls.map((control) => (
              <Select.Option key={control} value={control}>
                {control}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {!isExisting && (
          <Tooltip title="Require quality control to continue">
            <Form.Item label="Req. QC" name={[index, 'require_qc_ok']} valuePropName="checked">
              <Switch disabled={isExisting} />
            </Form.Item>
          </Tooltip>
        )}
        {isExisting && (
          <>
            <ApplicationField
              analysisType={analysisType}
              index={index}
              isExisting={isExisting}
              options={options}
              setApplication={setApplication}
              setApplicationObj={setApplicationObj}
              skipReceptionControl={skipReceptionControl}
              userContext={userContext}
            ></ApplicationField>
            <SubjectIdField
              isExisting={isExisting}
              caseName={form.getFieldValue('cases')[caseName]?.name}
              subjectId={subjectId}
              required={!isExisting}
              index={index}
              sampleId={sampleId}
            />
          </>
        )}
      </Row>
      {(isMIPDNA(analysisType) || isTomte(analysisType)) && (
        <Row className={styles.row}>
          <Form.Item label="Mother" name={[index, 'mother']}>
            <Select style={{ width: 182 }} allowClear>
              {parentsSamples?.mother
                ?.filter((sample) => sample.name !== sampleId)
                .map(({ name }) => (
                  <Select.Option key={name} value={name}>
                    {name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item label="Father" name={[index, 'father']}>
            <Select style={{ width: 182 }} allowClear>
              {parentsSamples?.father
                ?.filter((sample) => sample.name !== sampleId)
                .map(({ name }) => (
                  <Select.Option key={name} value={name}>
                    {name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          {isTomte(analysisType) && (
            <Form.Item
              label="Reference genome"
              name={[index, 'reference_genome']}
              rules={[{ required: !isExisting }]}
              required={!isExisting}
            >
              <Select style={{ width: 150 }} disabled={isExisting}>
                {balsamicReferenceGenomes.map(({ text, value }) => (
                  <Select.Option key={value} value={value}>
                    {text}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </Row>
      )}

      {isBalsamic(analysisType) && (
        <Row className={styles.row}>
          {analysisType === balsamicQC && (
            <Form.Item
              label="Reference genome"
              name={[index, 'reference_genome']}
              rules={[{ required: !isExisting }]}
              required={!isExisting}
            >
              <Select style={{ width: 150 }}>
                {balsamicReferenceGenomes.map(({ text, value }) => (
                  <Select.Option key={value} value={value}>
                    {text}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {isTumour && !isExisting && (
            <Tooltip title={'Tumour Purity (%)'}>
              <Form.Item label="Purity" name={[index, 'tumour_purity']}>
                <Input type={'number'} style={{ width: 70 }} suffix={'%'} disabled={isExisting} />
              </Form.Item>
            </Tooltip>
          )}
          {isPANPAZApplication(application) && (
            <Form.Item
              label="Bait set"
              name={[index, 'capture_kit']}
              rules={[
                {
                  required: !isExisting,
                  validator: (rule, value) => {
                    return validateBaitSet(value, !isExisting, options)
                  },
                },
              ]}
              required={!isExisting}
            >
              <Select style={{ width: 250 }} disabled={isExisting}>
                {options?.beds?.map((bed) => (
                  <Select.Option key={bed} value={bed}>
                    {bed}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </Row>
      )}
      {!isExisting && (
        <>
          <Row className={styles.row}>
            <SubjectIdField
              isExisting={isExisting}
              subjectId={subjectId}
              caseName={form.getFieldValue('cases')[caseName]?.name}
              index={index}
              sampleId={sampleId}
              required={!isExisting}
            />
            <ApplicationField
              analysisType={analysisType}
              index={index}
              isExisting={isExisting}
              options={options}
              setApplication={setApplication}
              setApplicationObj={setApplicationObj}
              skipReceptionControl={skipReceptionControl}
              userContext={userContext}
            ></ApplicationField>
            <Tooltip title="Age at sampling">
              <Form.Item label="Age" name={[index, 'age_at_sampling']}>
                <Input style={{ width: 47 }} />
              </Form.Item>
            </Tooltip>
          </Row>
          <Row className={styles.row}>
            <Tooltip title="Phenotype groups">
              <Form.Item label="Ph. groups" name={[index, 'phenotype_groups']}>
                <Select mode="tags" style={{ width: 210 }} />
              </Form.Item>
            </Tooltip>
            <Tooltip title="Phenotype terms">
              <Form.Item label="Ph. terms" name={[index, 'phenotype_terms']}>
                <Select mode="tags" style={{ width: 210 }} />
              </Form.Item>
            </Tooltip>
            <Form.Item label="Comment" name={[index, 'comment']}>
              <Input style={{ width: 310 }} />
            </Form.Item>
          </Row>
          <Divider style={{ marginTop: '-2px' }} />
          <Row className={styles.row}>
            <Tooltip title={'Elution buffer'}>
              <Form.Item
                label="Buffer"
                name={[index, 'elution_buffer']}
                rules={[
                  { required: isBufferRequired(application) },
                  {
                    validator: (rule, value) => {
                      return skipReceptionControl && value?.includes('Other')
                        ? Promise.reject()
                        : Promise.resolve()
                    },
                    message: skipReceptionControl
                      ? 'Only Tris-HCl and nuclease-free water allowed when skipping reception control.'
                      : '',
                  },
                ]}
                required={isBufferRequired(application)}
              >
                <Select style={{ width: 230 }}>
                  {buffers.map((buffer) => (
                    <Select.Option key={buffer}>{buffer}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Tooltip>
            <Form.Item
              label="Source"
              name={[index, 'source']}
              rules={[
                { required: true },
                {
                  message: (
                    <div>
                      FFPE samples cannot be run on
                      <br />
                      whole genome applications
                    </div>
                  ),
                  validator: (rule, value) => {
                    return application?.includes('WG') && value?.includes('FFPE')
                      ? Promise.reject()
                      : Promise.resolve()
                  },
                },
              ]}
              required
            >
              <Select style={{ width: 168 }} onChange={() => prefillConditionalFields()}>
                {options?.sources?.analysis?.map((panel) => (
                  <Select.Option key={panel} value={panel}>
                    {panel}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {isFormalineSource && (
              <>
                <Tooltip title={'Formalin fixation hours'}>
                  <Form.Item label="Fixation hrs" name={[index, 'formalin_fixation_time']}>
                    <Input style={{ width: 35 }} />
                  </Form.Item>
                </Tooltip>
                <Tooltip title={'Weeks after fixation'}>
                  <Form.Item label="Weeks fix." name={[index, 'post_formalin_fixation_time']}>
                    <Input style={{ width: 35 }} />
                  </Form.Item>
                </Tooltip>
                <Form.Item label="Tissue block" name={[index, 'tissue_block_size']}>
                  <Radio.Group size="small">
                    {tissueBlockSize.map((status) => (
                      <Radio.Button key={status} value={status}>
                        {status}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </>
            )}
            {isOtherSource && (
              <Form.Item
                label={'Which other source?'}
                name={[index, 'source_comment']}
                rules={[{ required: true }]}
                required
              >
                <Input placeholder={'Which other source?'} style={{ width: 245 }} />
              </Form.Item>
            )}
          </Row>
          <Row className={styles.row}>
            <Form.Item
              label="Container"
              name={[index, 'container']}
              rules={[{ required: true }]}
              required
            >
              <Select
                style={{ width: 170 }}
                onChange={(value) => {
                  prefillConditionalFields()
                  setContainerNameRequirement(value === '96 well plate')
                }}
              >
                {getAvailableContainersType(analysisType).map((container) => (
                  <Select.Option key={container} value={container}>
                    {container}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {hasContainer && (
              <>
                <Form.Item
                  label="Container name"
                  name={[index, 'container_name']}
                  rules={[{ required: containerNameRequirement }]}
                  required={containerNameRequirement}
                >
                  <Input
                    style={{ width: 207 }}
                    onChange={({ target }) => setContainerName(target.value)}
                  />
                </Form.Item>{' '}
                <Tooltip title={'Volume (μL). Must be between 20-130 μL'}>
                  <Form.Item
                    label="Volume (μL)"
                    name={[index, 'volume']}
                    rules={[
                      { required: true },
                      {
                        min: 20,
                        max: 130,
                        message: '20 to 130 μL',
                        type: 'number',
                      },
                    ]}
                    required
                  >
                    <InputNumber style={{ width: 90 }} />
                  </Form.Item>
                </Tooltip>
              </>
            )}
            {isPlate && (
              <Form.Item
                label="Well"
                name={[index, 'well_position']}
                rules={[
                  { required: true },
                  {
                    message: <div> This well already contains a sample </div>,
                    validator: (rule, well) => {
                      const cases = form.getFieldValue('cases')
                      const samples = cases.flatMap((localCase) => localCase.samples)
                      return validateWellIsFree(well, containerName, samples)
                    },
                  },
                ]}
                required
              >
                <Select style={{ width: 70 }}>
                  {plateLayout().map((well) => (
                    <Select.Option key={well} value={well}>
                      {well}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <Tooltip title={'Concentration (ng/μL)'}>
              <Form.Item
                label="Concentration (ng/μL)"
                name={[index, 'concentration_ng_ul']}
                required={skipReceptionControl}
                rules={
                  skipReceptionControl && applicationObj
                    ? getConcentrationRules(skipReceptionControl, applicationObj, source)
                    : []
                }
              >
                <InputNumber style={{ width: 65 }} />
              </Form.Item>
            </Tooltip>
          </Row>
        </>
      )}
      <DeletePopConfirm
        itemType={'sample'}
        action={deleteSample}
        itemName={caseName}
        triggerComponent={
          <Button icon={<DeleteTwoTone />} block>
            Sample
          </Button>
        }
      />
    </Card>
  )
}
